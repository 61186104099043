.glitter {
  transform-box: fill-box;
  transform-origin: center;
  opacity: 0;
  animation: glitter 6s linear;
}

@keyframes glitter {
  0% {
    transform: scale(0) rotate(0deg);
    opacity: 0;
  }
  25% {
    transform: scale(1) rotate(180deg);
    opacity: 1;
  }
  50% {
    transform: scale(0.3) rotate(360deg);
    opacity: 0;
  }
  100% {
    transform: scale(0) rotate(360deg);
    opacity: 0;
  }
}
