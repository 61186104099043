.react-datepicker {
  /* font-size: 1.2rem !important; */
  font-family: inherit !important;
  background-color: unset !important;
  border: 1px solid #3d495a !important;
  border-radius: 0.5rem !important;
  overflow: hidden;
}

.react-datepicker__current-month,
.react-datepicker__current-year {
  font-size: 1.3rem !important;
  color: #dedfe1 !important;
  /* font-family: "Roboto", sans-serif !important; */
}

.react-datepicker__header {
  padding-top: 6px !important;
  /* font-size: 1.2rem !important; */
  color: #dedfe1 !important;
  /* font-family: "Roboto", sans-serif !important; */
  border-bottom: 1px solid #3d495a !important;
}

.react-datepicker__navigation {
  top: 0.7rem !important;
  color: white !important;
}

.react-datepicker__day-name {
  margin: 0.5rem !important;
  color: #b1b2b4 !important;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__year-text {
  line-height: 1.5rem !important;
  margin: 0.5rem !important;
  color: #f2f3f5 !important;
}

.react-datepicker__month-text,
.react-datepicker__year-text {
  padding: 0.5rem !important;
}

.react-datepicker__month-container,
.react-datepicker__year-container,
.react-datepicker__year,
.react-datepicker__header {
  background-color: #323e4e !important;
}
.react-datepicker__year {
  margin: 0px !important;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__year-text--disabled {
  color: #404b5a !important;
}

.react-datepicker__day--selected,
.react-datepicker__month-text--selected,
.react-datepicker__year-text--selected {
  background-color: #5eb946 !important;
  color: #f8fafc !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__year-text:hover {
  background-color: #404b5a !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #404b5a !important;
  color: #f8fafc !important;
}

.react-datepicker__today-button {
  background-color: #323e4e !important;
  color: #f8fafc !important;
  border: 1px solid #293342 !important;
  padding: 1rem !important;
}
