.sky {
  background-repeat: no-repeat;
  background-size: cover;
}

.morningSky {
  /* background-image: linear-gradient(to bottom, #1f2937, #f1bd2e 36%, #f1bd2e8c, #4d4439, #1f2937); */
  background-image: linear-gradient(to bottom, #1f2937, #f1bd2e, rgba(255, 166, 64, 0.5) 45%, #4d4439, #1f2937, #1f2937);
}

.noonSky {
  /* background-image: linear-gradient(to bottom, #1f2937, rgb(243, 223, 109) 40%, #668d9c, #7c828555, #1f2937); */
  background-image: linear-gradient(
    to bottom,
    #1f2937,
    #8cc7df,
    rgba(240, 236, 215, 0.9) 20%,
    rgba(219, 212, 173, 0.9) 30%,
    rgba(211, 203, 158, 0.9) 40%,
    #7c8285,
    #1f2937
  );
}

.eveningSky {
  /* background-image: linear-gradient(to bottom, #1f2937, #4f9bb9 40%, #ffa540, #4d4439, #1f2937); */
  background-image: linear-gradient(to bottom, #1f2937, #4f9bb9 20%, #ffa540, #1f2937, #1f2937);
}

.nightSky {
  /* background-image: linear-gradient(to bottom, #1f2937, #336174 40%, #7c828555, #1f2937); */
  background-image: linear-gradient(to bottom, #1f2937, #336174 20%, #1f2937, #1f2937);
}

#ngenHisa,
#hisaNgen,
#solarNgen,
#batNgen,
#ngenBat,
#omaricaNgen,
#ngenOmarica,
#omaricaNgen,
#ngenHisa,
#scHeatpump {
  -webkit-transform: translateY(-10.6%);
  transform: translateY(-10.6%);
}

#pbBuildMeter,
#pbMeterGrid,
#stevBatPb,
#batStevPb,
#stevGridPb,
#gridStevPb,
#stavStevPb,
#stevStavPb,
#stevSolarPb,
#solarStevPb {
  -webkit-transform: translateY(-9.9%);
  transform: translateY(-9.9%);
}

#hydroIn,
#hydroOut {
  -webkit-transform: translateY(-20.85%);
  transform: translateY(-20.85%);
}
#solarIn,
#solarOut {
  -webkit-transform: translateY(-9.67%);
  transform: translateY(-9.67%);
}
#windIn,
#windOut {
  -webkit-transform: translateY(-17.6%);
  transform: translateY(-17.6%);
}

.offline g[clip-path*="lottie"] path {
  stroke: #ea580c;
}
