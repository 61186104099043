.circle {
  animation: spinner 1.6s infinite ease-in;
  fill: grey;
  opacity: 0;
}

.c1 {
  animation-delay: 0.2s;
}
.c2 {
  animation-delay: 0.4s;
}
.c3 {
  animation-delay: 0.6s;
}
.c4 {
  animation-delay: 0.8s;
}

@keyframes spinner {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  80% {
    opacity: 0;
  }
}
