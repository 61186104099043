@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-shadow {
    text-shadow: 0px 0px 2px var(--tw-shadow-color);
  }
}

.highcharts-root {
  font-family: "Inter var", sans-serif !important;
  background: unset !important;
}

.highcharts-background {
  fill: transparent;
}
